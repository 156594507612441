import { Navigate } from 'react-router-dom';
import MainDashboard from '../views/dashboard/MainDashboard';
import AuthLayout from '../Layouts/AuthLayout';
import EwalletLayout from '../views/ewallet/MainEwallet';
import PayoutLayout from '../views/payout/MainPayout';
import RegisterLayout from '../views/register/MainRegister';
import ProfileLayout from '../views/profile/MainProfile';
import GenealogyTree from '../views/tree/GenealogyTree';
import TreeView from '../views/tree/TreeView';
import EpinLayout from '../views/epin/MainEpin';
import ShoppingLayout from '../views/shopping/ShoppingLayout';
import ProductDetailsLayout from '../views/shopping/ProductDetailsLayout';
import CheckoutLayout from '../views/shopping/CheckoutLayout';
import Faqs from '../components/Tools/Faqs';
import News from '../components/Tools/News';
import DownlineMembers from '../views/tree/DownlineMembers';
import SponserTree from '../views/tree/SponserView';
import ReferralMembers from '../views/tree/ReferralMembers';
import Leads from '../views/Crm/Leads';
import ComingSoon from '../components/Common/ComingSoon';
import BackToOffice from '../components/Auth/BackToOffice';
import RegisterComplete from '../views/register/RegisterComplete';
import KycDetails from '../components/Profile/KycDetails';
import ReplicaSite from '../views/Tools/ReplicaSite';
import LeadsForm from '../components/Crm/LeadsForm';
import DownloadMaterials from '../views/Tools/DownloadMaterials';
import ReplicaLayout from '../Layouts/ReplicaLayout';
import MainReplica from '../views/replica/MainReplica';
import ReplicaRegisterLayout from '../views/replica/MainReplicaRegister';
import { ForgotPasswordForm } from '../components/Auth/forgotPassword';
import RepurchaseReport from '../views/shopping/RepurchaseReportLayout';
import PurchaseInvoice from '../components/shopping/PurchaseInvoice';
import RewardValueDetails from '../views/reports/RewardValueDetails';
import TlReport from '../views/reports/TlReport';
import ReleasedRewards from '../views/reports/ReleasedRewards';
import BvSvReport from '../views/reports/BvSvReport';
import TlReportSponser from '../views/reports/TlReportSponser';
import BonusSponsor from '../views/reports/BonusSponsor';
import DailyGenerationSvReport from '../views/reports/DailyGenerationSvReport';
import GenerationBonusBvReport from '../views/reports/GenerationBonusBvReport';
import PrivilegeCustomer from '../views/reports/PrivilegeCustomer';

const publicRoutes = [
  {
    path: '/',
    element: <Navigate to="/login" replace />
  },
  {
    path: '/login/:adminUsername?/:username?',
    element: <AuthLayout />
  },
  {
    path: '/oc-login/?:string?:db_prefix?',
    element: <BackToOffice />
  },
  {
    path: '/lcp/:username?/:hash?',
    element: <LeadsForm />
  },
  {
    path: '/replica/:username/:hashKey',
    element: <ReplicaLayout><MainReplica /></ReplicaLayout>
  },
  {
    path: '/replica-register',
    element: <ReplicaLayout><ReplicaRegisterLayout /></ReplicaLayout>
  },
  {
    path: '/forgot-password/:hashKey',
    element: <ForgotPasswordForm />
  }
];

const privateRoutes = [
  {
    path: '/dashboard',
    element: <MainDashboard />,
  },
  {
    path: '/networks',
    element: <Navigate to='/genealogy-tree' replace />
  },
  {
    path: '/e-wallet',
    element: <EwalletLayout />
  },
  {
    path: '/e-pin',
    element: <EpinLayout />
  },
  {
    path: '/payout',
    element: <PayoutLayout />
  },
  {
    path: '/genealogy-tree',
    element: <GenealogyTree />
  },
  {
    path: '/sponsor-tree',
    element: <SponserTree />
  },
  {
    path: '/tree-view',
    element: <TreeView />
  },
  {
    path: '/downline-members',
    element: <DownlineMembers />
  },
  {
    path: '/referral-members',
    element: <ReferralMembers />
  },
  {
    path: '/register/:parent?/:position?',
    element: <RegisterLayout />
  },
  {
    path: '/profile',
    element: <ProfileLayout />
  },
  {
    path: '/shopping',
    element: <ShoppingLayout />
  },
  {
    path: '/product-details/:id',
    element: <ProductDetailsLayout />
  },
  {
    path: '/checkout',
    element: <CheckoutLayout />
  },
  {
    path: '/faqs',
    element: <Faqs />
  },
  {
    path: '/news/:newsId?',
    element: <News />
  },
  {
    path: '/leads',
    element: <Leads />
  },
  {
    path: '/settings',
    element: <ComingSoon />
  },
  {
    path: '/downloads',
    element: <ComingSoon />
  },
  // {
  //   path: '/mailbox',
  //   element: <MailBox />
  // },
  {
    path: '/support-center',
    element: <ComingSoon />
  },
  {
    path: '/crm',
    element: <ComingSoon />
  },
  {
    path: '/download-materials',
    element: <DownloadMaterials />
  },
  {
    path: '/replica-site',
    element: <ReplicaSite />
  },
  {
    path: '/promotion-tools',
    element: <ComingSoon />
  },
  {
    path: '/add-lead',
    element: <ComingSoon />
  },
  {
    path: '/view-lead',
    element: <ComingSoon />
  },
  {
    path: '/graph',
    element: <ComingSoon />
  },
  {
    path: '/registration-complete/:username?',
    element: <RegisterComplete />
  },
  {
    path: '/kyc-details',
    element: <KycDetails />
  },
  // {
  //   path: '/upgrade',
  //   element: <Upgrade />
  // },
  // {
  //   path: '/renew',
  //   element: <Renew />
  // },
  {
    path:'/repurchase-report',
    element: <RepurchaseReport />
  },
  {
    path:'/repurchase-invoice/:id',
    element: <PurchaseInvoice />
  },
  {
    path:'/reward_value_details',
    element: <RewardValueDetails />
  },
  {
    path:'/tl_report',
    element: <TlReport />
  },
  {
    path:'/released_rewards',
    element: <ReleasedRewards />
  },
  {
    path:'/bvsv_report',
    element: <BvSvReport />
  },
  {
    path:'/tl_report_sponsor',
    element: <TlReportSponser />
  },
  {
    path:'/bonus_sponsor',
    element: <BonusSponsor />
  },
  {
    path:'/daily_generation_sv_report',
    element: <DailyGenerationSvReport />
  },
  {
    path:'/generation_bonus_bv_report',
    element: <GenerationBonusBvReport />
  },
  {
    path:'/privilege-customer',
    element: <PrivilegeCustomer />
  },

]

export { privateRoutes, publicRoutes }
