import React, { useState } from 'react'
import TableMain from '../../components/Common/table/TableMain.jsx';
import { ApiHook } from '../../hooks/apiHook.js';
import { useTranslation } from 'react-i18next';
// import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ReportService } from '../../services/reports/reports.js';
import ReportTableFilter from '../../components/Common/table/ReportTableFilter.jsx';
// import { InvestmentService } from '../../services/investment/investment';


const TlReportSponser = () => {
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState("statement");
    const [currentPage, setCurrentPage] = useState(1);
    const userSelectedCurrency = useSelector(
        (state) => state.user?.selectedCurrency
    );



    const [selectStatement, setSelectedStatement] = useState(false);
    const [selectedTransfer, setSelectedTransfer] = useState(false);
    const [selectedPurchase, setSelectedPurchase] = useState(false);
    const [selectedEarnings, setSelectedEarnings] = useState(false);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [selectedCategory, setSelectedCategory] = useState([]);
    const [dateRange, setDateRange] = useState({ startDate: "", endDate: "" });
   
    const handleTabChange = (tab) => {
        setActiveTab(tab);
        setCurrentPage(1);
        setApiTab(tab);
    };
  
    const setApiTab = (tab) => {
        if (tab === "transfer_history") {
            setSelectedTransfer(true);
            setSelectedCategory([]);
        } else if (tab === "purchase_wallet") {
            setSelectedPurchase(true);
        } else if (tab === "my_earnings") {
            setSelectedEarnings(true);
            setSelectedCategory([]);
        } else {
            setSelectedStatement(true);
        }
    };
    const headers = [
        t("SERIAL NUMBER"),
        t("DATE"),
        t("DESCRIPTION"),
        t("TRANSACTION FEE"),
        t("AMOUNT"),
        t("BALANCE")   
    ];
    
    const Report = ReportService.CallTlReportSponser(dateRange.endDate, dateRange.startDate);

    const dummyData = [

        {
            amount: '199.0000',
            createdAt: '2023-10-25T04:47:52.000Z',
            roi: '0.00'
        },
        {
            amount: '199.0000',
            createdAt: '2023-10-25T04:47:52.000Z',
            roi: '0.00'
        }
    ]

    return (
        <>
            <div className="page_head_top">{("TL Report Sponser")}</div>
            <div className="ewallet_table_section" style={{ marginTop: '20px' }}>
                <div className="table-responsive min-hieght-table">
                    <ReportTableFilter
                        setActiveTab={setActiveTab}
                        activeTab={activeTab}
                        headers={headers}
                        data={Report?.data?.data?.data}
                        type={"tlReportSponser"}
                        setDateRange={setDateRange}
                    />
                    <TableMain
                        headers={headers}
                        data={Report?.data?.data?.data}
                        // data={dummyData}
                        startPage={1}
                        // currentPage={statement?.data?.currentPage}
                        // totalPages={statement?.data?.totalPages}
                        currentPage={1}
                        totalPages={1}
                        setCurrentPage={setCurrentPage}
                        type={"tlReportSponser"}
                        itemsPerPage={itemsPerPage}
                        setItemsPerPage={setItemsPerPage}
                        activeTab={activeTab}
                        setApiTab={setApiTab}
                    />
                </div>
            </div>
        </>
    );
}

export default TlReportSponser