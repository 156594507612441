import React from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { PAYPAL_CLIENT_ID } from "../../config/config";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

const MyPayPalButton = ({ currency, price, type, data }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const subscriptionPlan = {
    plan_id: "P-0C686669RW515132KMVBWDKQ",
    start_time: new Date(Date.now() + 24 * 60 * 60 * 1000).toISOString(),
    quantity: 1,
    return_url: "http://192.168.6.13:3000/profile",
  };

  const paypalSubscriptionOptions = {
    "client-id": PAYPAL_CLIENT_ID,
    currency: currency,
    vault: true,
    intent: "subscription",
  };

  const paypalOptions = {
    "client-id": PAYPAL_CLIENT_ID,
    currency: currency,
  };

  const handleSubscriptionApproval = (data, actions) => {
    console.log("Subscription Details:", data);
    if (data.subscriptionID && data.orderID) {
      toast.success(t("subscription_created"));
      navigate("/profile");
    }
  };
  const handleOrderApproval = (data, actions) => {
    console.log("payment Details:", data);
  };

  return (
    <div>
      {type === "subscription" && (
        <PayPalScriptProvider options={paypalSubscriptionOptions}>
          <PayPalButtons
            style={{ layout: "vertical" }} // Change the layout to "vertical"
            createSubscription={(data, actions) => {
              return actions.subscription.create({
                plan_id: subscriptionPlan.plan_id,
                start_time: subscriptionPlan.start_time,
                quantity: subscriptionPlan.quantity,
              });
            }}
            onApprove={handleSubscriptionApproval}
          />
        </PayPalScriptProvider>
      )}
      {type === "normal" && (
        <PayPalScriptProvider options={paypalOptions}>
          <PayPalButtons
            style={{ layout: "vertical" }}
            createOrder={(data, actions) => {
              return actions.order.create({
                purchase_units: [
                  {
                    amount: {
                      value: price,
                    },
                  },
                ],
              });
            }}
            onApprove={handleOrderApproval}
          />
        </PayPalScriptProvider>
      )}
    </div>
  );
};

export default MyPayPalButton;
