import { useMutation, useQuery } from "@tanstack/react-query";
import API from "../../api/api";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { t } from "i18next";


const callApi = async (endpoint) => {
    try {
        const response = await API.get(endpoint);
        if (response.status === 200) {
            return response?.data;
        } else {
            return response?.data;
        }
    } catch (error) {
        console.log(error);
        throw error;
    }
};
const PostApi = async (endpoint, data) => {
    try {
        const response = await API.post(endpoint, JSON.stringify(data));
        return response;
    } catch (error) {
        console.log(error);
        throw error;
    }
};

export const ReportService = {
    CallRewardValueDetails: (start, end) => useQuery(["reward-value-details", start, end], () => callApi(`reward-value-details?start=${start}&end=${end}`)),
    CallTlReport: (start, end) => useQuery(["tl-rank", start, end], () => callApi(`tl-rank?start=${start}&end=${end}`)),
    CallTlReleasedRewards: (start, end) => useQuery(["get-reward-release", start, end], () => callApi(`get-reward-release?start=${start}&end=${end}`)),
    CallBvSvReport: (start, end) => useQuery(["get-bv-earnings", start, end], () => callApi(`get-bv-earnings?start=${start}&end=${end}`)),
    CallTlReportSponser: (start, end) => useQuery(["referral-rank-report", start, end], () => callApi(`referral-rank-report?start=${start}&end=${end}`)),
    // CallBonusSponser: (start, end) => useQuery(["referral-rank-report", start, end], () => callApi(`referral-rank-report?start=${start}&end=${end}`)),
    CallGenerationBonusReport: (start, end) => useQuery(["get-bv-generations", start, end], () => callApi(`get-bv-generations?start=${start}&end=${end}`)),
    CallDailyGenerationSvReport: (start, end) => useQuery(["get-pv-generations", start, end], () => callApi(`get-pv-generations?start=${start}&end=${end}`)),
    CallPrivilegeCustomer: (start, end) => useQuery(["privilege-customer", start, end], () => callApi(`privilege-customer?start=${start}&end=${end}`)),

}